import * as React from "react";
import { useAuth } from "../auth/AuthProvider";
import { useEffect, useState } from "react";
import { GET, POST } from "../util/ApiUtils";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";
import ReactModal from "react-modal";
import SSCButton from "../components/SSCButton";
import { EmailCampaign } from "../types/EmailCampaign";

const CAMPAIGN_VIEW = {
  CURRENT: "current",
  PAST: "past",
} as const;

type VIEW_KEYS = keyof typeof CAMPAIGN_VIEW;
type VIEW = (typeof CAMPAIGN_VIEW)[VIEW_KEYS];

const Dashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [emailCampaigns, setEmailCampaigns] = useState<EmailCampaign[]>([]);
  const [pastCampaigns, setPastCampaigns] = useState<EmailCampaign[]>([]);
  const [campaignToDelete, setCampaignToDelete] = useState<EmailCampaign>(
    {} as EmailCampaign
  );
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [campaignView, setCampaignView] = useState<VIEW>(CAMPAIGN_VIEW.CURRENT);
  // const [canRefreshStats, setCanRefreshStats] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savingCampaignId, setSavingCampaignId] = useState<string>("");
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const [error, setError] = useState<string>("");

  useEffect(() => {
    load();
  }, []);

  const load = async () => {
    const resp = await GET({
      endpoint: "campaigns/user",
      params: { userId: user?.id },
    });

    let campaigns: EmailCampaign[] = resp?.data?.emailCampaigns;

    const startedCampaignId = location?.state?.startedCampaignId;
    if (startedCampaignId) {
      campaigns = campaigns?.map((campaign) =>
        campaign?.id === startedCampaignId
          ? { ...campaign, started: true }
          : campaign
      );
    }

    setEmailCampaigns(campaigns);
    setPastCampaigns(resp?.data?.pastCampaigns);
    // setCanRefreshStats(resp?.data?.canRefreshStats);
  };

  const startCampaign = async (campaignId: string) => {
    setIsSaving(true);
    setSavingCampaignId(campaignId);
    // const resp = await POST({endpoint: 'campaigns/startCampaign', body: {campaignId}});
    POST({ endpoint: "campaigns/startCampaign", body: { campaignId } });

    setEmailCampaigns(
      (campaigns) =>
        campaigns?.map((campaign) =>
          campaign?.id === campaignId
            ? { ...campaign, started: true }
            : campaign
        )
    );

    // if(resp?.data?.error) {
    //   setError('Error starting campaign')
    //   setTimeout(() => setError(''), 5000)
    // }
    // else {
    //   const campaign = resp?.data?.campaign;
    //   setEmailCampaigns(emailCampaigns.map(emailCampaign => emailCampaign?.id === campaign?.id ? campaign : emailCampaign));
    // }
    setSavingCampaignId("");
    setIsSaving(false);
  };

  const openDeleteModal = (campaign: any) => {
    setCampaignToDelete(campaign);
    setShowDeleteModal(true);
  };

  const deleteCampaign = async () => {
    setIsDeleting(true);
    const resp = await POST({
      endpoint: "campaigns/delete",
      body: { campaignId: campaignToDelete?.id },
    });
    const deletedCampaignId = resp?.data?.deletedCampaignId;
    const updatedCampaigns = emailCampaigns?.filter(
      (ec) => ec?.id !== deletedCampaignId
    );
    const updatedPasCampaigns = pastCampaigns?.filter(
      (ec) => ec?.id !== deletedCampaignId
    );
    setEmailCampaigns(updatedCampaigns);
    setPastCampaigns(updatedPasCampaigns);
    setShowDeleteModal(false);
    setIsDeleting(false);
  };

  const refreshStats = async () => {
    setIsRefreshing(true);
    const resp = await POST({ endpoint: "campaigns/refreshStats" });
    if (resp?.data?.emailCampaigns) {
      setEmailCampaigns(resp?.data?.emailCampaigns);
      // setCanRefreshStats(resp?.data?.canRefreshStats);
    }

    setIsRefreshing(false);
  };

  const displayCampaignScheduleStatus = (campaign: any) => {
    if (!campaign?.started && campaign?.scheduled) {
      return (
        <>
          <div className="campaignScheduledDiv">
            <p>
              This campaign is scheduled to send at {campaign?.hour} on{" "}
              {campaign?.date}
            </p>
          </div>
        </>
      );
    }
  };

  const getDashboard = (campaign: any) => {
    if (campaign?.started || campaign?.scheduled) {
      return (
        <div className="dashboardBoxSection--2">
          <div className="dashboardBox--labelAndNumber">
            {/*<div className="dashboardBox--data">
              <p className="textLabel">Open Rate</p>
              <p className="dataText">{campaign?.openedPercent || 0}%</p>
            </div>*/}
            <div className="dashboardBox--data">
              <p className="textLabel">Reply Rate</p>
              <p className="dataText">{campaign?.replyPercent || 0}%</p>
            </div>
            <div className="dashboardBox--data">
              <p className="textLabel">Emails sent</p>
              <p className="dataText">{campaign?.sent || 0}</p>
            </div>
          </div>

          {/* <ProgressBar num={campaign?.numPeople} total={100} /> */}
        </div>
      );
    } else if (campaign?.numPeople) {
      return (
        <div className="dashboardBox--peopleAdded">
          <p className="textLabel">People added</p>
          <ProgressBar num={campaign?.numPeople} total={100} />
        </div>
      );
    } else {
      return (
        <div className="dashboardBoxSection--2">
          <p className="boldTextInBox centerText">No data.</p>
          <p className="boldTextInBox centerText">Add people to start</p>
        </div>
      );
    }
  };

  return (
    <>
      <div className="pageHeaderContainer">
        <div>
          <button
            className="currentVsPastCampaignText marginRight24px"
            onClick={() => setCampaignView(CAMPAIGN_VIEW.CURRENT)}
            style={{
              color: campaignView === CAMPAIGN_VIEW.CURRENT ? "#000" : "#aaa",
            }}
          >
            Active Sequences
          </button>
          <button
            className="currentVsPastCampaignText"
            onClick={() => setCampaignView(CAMPAIGN_VIEW.PAST)}
            style={{
              color: campaignView === CAMPAIGN_VIEW.PAST ? "#000" : "#aaa",
            }}
          >
            Past Sequences
          </button>
        </div>

        <div className="dashboard_buttonDiv">
          <SSCButton
            className={"mainButton opacity50 marginRight12px"}
            onClick={refreshStats}
            isLoading={isRefreshing}
          >
            <span>⟳</span> Refresh Stats
          </SSCButton>
          <Link to="/personalize" className="linkInButton">
            <button className="mainButton">Start Selling!</button>
          </Link>
        </div>
      </div>

      {error !== "" && (
        <div className="container--maxWidth1100px paddingBottom24px">
          <div className="errorMessageOnDashboard">
            <p className="textColor--white">{error}</p>
          </div>
        </div>
      )}

      <div className="container--maxWidth1100px container--paddingBottom64px">
        {campaignView === CAMPAIGN_VIEW.CURRENT && (
          // *** Current Campaigns List ***
          <>
            {emailCampaigns?.length === 0 ? (
              <div className="dashboard-noCampaigns">
                <div>
                  <h4>You don't have any email campaigns yet.</h4>
                  <p>
                    Create a campaign to get started. And if you don't know how,
                    don't worry. We will help you along the way.
                  </p>
                </div>
              </div>
            ) : (
              emailCampaigns?.map((campaign: EmailCampaign) => {
                let campaignTitle = "Name";
                if (campaign?.createdAt) {
                  campaignTitle = `Created on ${campaign?.createdAt}`;
                } else if (campaign?.startedAt) {
                  campaignTitle = `Started on ${campaign?.startedAt}`;
                }

                return (
                  <div key={campaign?.id}>
                    {displayCampaignScheduleStatus(campaign)}

                    <div className="display-flex box--border--black dashboardBox positionRelative ">
                      <div className="dashboardBoxSection--1">
                        <p className="labelInBox">{campaignTitle}</p>
                        <p className="boldTextInBox">{campaign?.name}</p>
                      </div>
                      {getDashboard(campaign)}
                      <div className="dashboardBoxSection--3">
                        {!campaign?.started && !campaign?.scheduled && (
                          <>
                            {campaign?.canStart && (
                              <SSCButton
                                text="Start"
                                onClick={() => startCampaign(campaign?.id)}
                                isLoading={savingCampaignId === campaign?.id}
                                disabled={isSaving}
                                className="mainButton marginBottom16px"
                              />
                            )}
                            {/* <button
                              className="mainButton mainButton--outlined  marginBottom16px"
                              onClick={() =>
                                navigate("/addPeople", {
                                  state: { campaignId: campaign?.id },
                                })
                              }
                            >
                              Add people
                            </button> */}
                          </>
                        )}

                        {campaign?.canEdit && (
                          <button
                            className="mainButton mainButton--outlined  marginBottom16px"
                            onClick={() =>
                              navigate(`/campaign/${campaign?.id}`)
                            }
                          >
                            Edit
                          </button>
                        )}

                        <button
                          className="mainButton mainButton--outlined  marginBottom16px"
                          onClick={() => openDeleteModal(campaign)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </>
        )}

        {campaignView === CAMPAIGN_VIEW.PAST && (
          // *** Past Campaigns List ***
          <>
            {pastCampaigns?.length === 0 ? (
              <div className="dashboard-noCampaigns">
                <div>
                  <h4>You don't have past email campaigns yet.</h4>
                  <p>
                    Once your campaign finishes sending, it will show up in here
                    to keep things organized.
                  </p>
                </div>
              </div>
            ) : (
              pastCampaigns?.map((campaign: EmailCampaign) => {
                let campaignTitle = "Name";
                if (campaign?.createdAt) {
                  campaignTitle = `Created on ${campaign?.createdAt}`;
                } else if (campaign?.startedAt) {
                  campaignTitle = `Started on ${campaign?.startedAt}`;
                }

                return (
                  <div key={campaign?.id}>
                    {displayCampaignScheduleStatus(campaign)}

                    <div className="box--border--black dashboardBox positionRelative display-flex">
                      <div className="dashboardBoxSection--1">
                        <p className="labelInBox">{campaignTitle}</p>
                        <p className="boldTextInBox">{campaign?.name}</p>
                      </div>
                      {getDashboard(campaign)}
                      <div className="dashboardBoxSection--3">
                        {!campaign?.started && !campaign?.scheduled && (
                          <>
                            <SSCButton
                              text="Start"
                              onClick={() => startCampaign(campaign?.id)}
                              isLoading={savingCampaignId === campaign?.id}
                              disabled={isSaving}
                              className="mainButton marginBottom16px"
                            />
                            <button
                              className="mainButton mainButton--outlined  marginBottom16px"
                              onClick={() =>
                                navigate("/addPeople", {
                                  state: { campaignId: campaign?.id },
                                })
                              }
                            >
                              Add people
                            </button>
                          </>
                        )}

                        <button
                          className="mainButton mainButton--outlined  marginBottom16px"
                          onClick={() => navigate(`/campaign/${campaign?.id}`)}
                        >
                          Edit
                        </button>

                        <button
                          className="mainButton mainButton--outlined  marginBottom16px"
                          onClick={() => openDeleteModal(campaign)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </>
        )}
      </div>

      <ReactModal
        isOpen={showDeleteModal}
        onRequestClose={() => setShowDeleteModal(false)}
        onAfterClose={() => setCampaignToDelete({} as EmailCampaign)}
        closeTimeoutMS={200}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            border: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
          },
          content: {
            width: "480px",
            height: "auto",
            margin: "auto",
            inset: "auto",
            paddingRight: 32,
            paddingLeft: 32,
          },
        }}
        ariaHideApp={false}
      >
        <p
          className="modal--closeButton"
          onClick={() => setShowDeleteModal(false)}
        >
          Close
        </p>

        <h2>
          We're about to delete this... <br />
          For. E. Ver.
        </h2>
        <p>
          Are you sure you want to delete the "{campaignToDelete?.name}"
          campaign?
        </p>
        <p className="marginBottom32px">This action cannot be undone.</p>
        <div className="modal__buttonContainer--rightLeft">
          <button
            className="mainButton opacity50 marginRight12px"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </button>
          <SSCButton
            text="Confirm"
            onClick={deleteCampaign}
            isLoading={isDeleting}
            className="mainButton"
          />
        </div>
      </ReactModal>
    </>
  );
};

export default Dashboard;
